import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import {
  faBtc, faEthereum, faTether,
} from '@fortawesome/free-brands-svg-icons';
import { set } from 'react-hook-form';

const CryptoConverter = () => {
  const [cryptoRates, setCryptoRates] = useState({});
  const [selectedCrypto, setSelectedCrypto] = useState('BTC');
  const [hkdAmount, setHkdAmount] = useState('1000');
  const [cryptoAmount, setCryptoAmount] = useState('');
  const [loading, setLoading] = useState(false);
  const [asofDate, setAsofDate] = useState('');

  // Fetch the crypto rates on component mount
  const fetchCryptoRates = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        '/data/crypto_cache.json',
      );
      setCryptoRates(response.data.crypto);
      setAsofDate(new Date(response.data.date).toLocaleString());
      setLoading(false);
    } catch (error) {
      console.error('Error fetching crypto rates:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCryptoRates();
  }, []);

  useEffect(() => {
    handleHKDToCrypto();
  }, [cryptoRates]);

  // Handle conversion from Crypto to HKD
  const handleCryptoToHKD = (e) => {
    const rate = cryptoRates[selectedCrypto];
    if (!rate || !cryptoAmount) return;
    setHkdAmount((cryptoAmount * rate).toFixed(2));
  };

  // Handle conversion from HKD to Crypto
  const handleHKDToCrypto = (e) => {
    const rate = cryptoRates[selectedCrypto];
    if (!rate || !hkdAmount) return;
    setCryptoAmount((hkdAmount / rate).toFixed(6));
  };

  // on SelectedCrypto change, update both fields
  useEffect(() => {
    // handleCryptoToHKD();
    handleHKDToCrypto();
  }, [selectedCrypto]);

  return (
    <div className='container mt-4 fade-in'>
      <h2 className='text-center'>Crypto ↔ TBT Converter</h2>
      {loading ? (
        <div className='text-center mt-3'>
          <div className='spinner-border text-primary' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </div>
        </div>
      ) : (
        <form className='mt-4'>
          {/* Select Cryptocurrency */}
          <div className='mb-3'>
            <label htmlFor='crypto' className='form-label'>
              Select Cryptocurrency
            </label>
            <div className='input-group'>
              {/* Icon at the beginning */}
              <span className='input-group-text' style={{ minWidth: '64px' }}>
                <img src={'/images/logo-crypto-' + selectedCrypto + '.svg'} alt='' style={{ height: '32px' }}/>
              </span>
              <select
                id='crypto'
                className='form-select'
                value={selectedCrypto}
                onChange={(e) => setSelectedCrypto(e.target.value)}
              >
                {Object.keys(cryptoRates).map((crypto) => (
                  <option key={crypto} value={crypto}>
                    {crypto}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Crypto to HKD */}
          <div className='mb-3'>
            <label htmlFor='cryptoAmount' className='form-label'>
              Amount in {selectedCrypto}
            </label>
            <input
              type='number'
              id='cryptoAmount'
              className='form-control'
              value={cryptoAmount}
              onChange={(e) => setCryptoAmount(e.target.value)}
              onKeyUp={handleCryptoToHKD}
              placeholder={`Enter amount in ${selectedCrypto}`}
            />
          </div>

          {/* HKD to Crypto */}
          <div className='mb-3'>
            <label htmlFor='hkdAmount' className='form-label'>
              Amount in TBT
            </label>
            <input
              type='number'
              id='hkdAmount'
              className='form-control'
              value={hkdAmount}
              onChange={(e) => setHkdAmount(e.target.value)}
              onKeyUp={handleHKDToCrypto}
              placeholder='Enter amount in TBT'
            />
          </div>
        </form>
      )}

      {/* Display Conversion Results */}
      {cryptoAmount && hkdAmount && (
        <div className='alert alert-info mt-4' role='alert'>
          {cryptoAmount} {selectedCrypto} ≈ {hkdAmount} TBT as of {asofDate}
        </div>
      )}
    </div>
  );
};

export default CryptoConverter;
