import axios from 'axios';
import { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import {
  useParams, useNavigate, useOutletContext,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Swal from 'sweetalert2'
import { useForm } from 'react-hook-form';
import {
  Col, Button, Form, FormControl, InputGroup, FormLabel, Spinner,
} from 'react-bootstrap';
import { createCheckoutSession } from '../services/PaymentService';
import * as config from '../config'
import useAuth from '../hooks/useAuth';
import CoinGeckoWidget from '../components/CoinGeckoWidget';
import CryptoConverter from '../components/CryptoConverter';

function Buy() {
  const { t, i18n } = useTranslation();

  const title = t('token-purchase-platform-title');
  const { getSession } = useAuth();
  const user = getSession();
  const navigate = useNavigate();
  const { paymentStatus } = useParams();
  const [unitPrice, setUnitPrice] = useState(1);
  const [quantity, setQuantity] = useState(1000);
  const [wallet, setWallet] = useState(user.metadata?.solana_wallet_address ?? '');
  const [checkOutURL, setCheckOutURL] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('none') // crypto, card;
  const [userLocation, setUserLocation] = useState(null);
  const [isHK, setIsHK] = useState(false);
  const [isCN, setIsCN] = useState(false);

  const {
    register, handleSubmit, watch, setValue, formState: { errors },
  } = useForm();
  const vaildationRule = {
    walletAddressLen: 42,
    minQty: 1000,
  };
  const context = useOutletContext();

  const handlePayment = async (data) => {
    let response;
    setIsLoading(true);
    try {
      let fullURL = '';
      if (window) {
        fullURL = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}`;
      }

      response = await createCheckoutSession({
        quantity: data.quantity, // Number of BB Tokens
        email: user.email, // Customer email
        walletAddress: data.wallet, // Customer wallet address
        success_url: `${fullURL}/console/buy/success`, // Success URL
        cancel_url: `${fullURL}/console/buy/incomplete`, // Cancel URL
        locale: i18n.language === 'en' ? 'en' : 'zh-HK',
        refCode: data.refCode ?? 'NONE',
        isHK,
      });

      // Redirect to Stripe Checkout page
      if (response) {
        setCheckOutURL(response.url)
        setQuantity(data.quantity)
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
      context.setAlertOpts({
        text: error.response?.data?.message ?? error.message ?? error.error,
        icon: 'error',
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (user) {
      console.log('user', JSON.stringify(user, null, 2));
      getLocationByIP(user.userIP).then(
        (location) => {
          setUserLocation(location)
          if (location.includes('Hong Kong')) {
            setIsHK(true)
          }
          if (location.includes('China')) {
            setIsCN(true)
          }
        },
      )
    }
  }, []);

  useEffect(() => {
    if (checkOutURL) {
      // Show the confirmation dialog using Swal
      Swal.fire({
        title: t('tp-confirm-purchase'),
        text: `${t('tp-confirm-purchase-text')} ${quantity} TBT`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: t('tp-confirm-purchase-btn'),
        cancelButtonText: t('tp-confirm-purchase-cancel'),
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            // Redirect user to Stripe checkout page
            window.location.href = checkOutURL;
          } catch (error) {
            Swal.fire('Error', 'Something went wrong with the payment. Please try again.', 'error');
          }
        } else {
          setCheckOutURL(null)
        }
      });
    }
  }, [checkOutURL]);

  const getLocationByIP = async (ip = '') => {
    try {
      const response = await axios.get(`${config.API_URL}/auth/location`);
      const dataIP = response.data;
      if (dataIP.country) {
        return `${dataIP.regionName}, ${dataIP.country}`;
      }
      throw new Error('Failed to retrieve location');
    } catch (error) {
      console.error(error.message);
      return 'Unknown';
    }
  }

  if (!paymentStatus) {
    return (
      <>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <div className="container-fluid p-0">
          <div
            className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 className="h2">{title}</h1>
          </div>
          <p>
            {t('token-purchase-platform-text')}
            <br />
            {paymentMethod === 'none' && <>
              <div className='row fade-in' style={{ fontSize: '1.5em' }}>
                <div className='col-12 col-md-6'>
                  <div className='card card-body my-3 bg-tr-dark'>
                    <button className='button button-gold my-4 px-3 py-2 rounded-pill'
                      onClick={() => setPaymentMethod('card')}
                    > <i className="bi bi-credit-card"></i> CARD </button>
                  </div>
                </div>
                <div className='col-12 col-md-6'>
                  <div className='card card-body my-3 bg-tr-dark'>
                    <button className='button button-gold my-4 px-3 py-2 rounded-pill'
                      onClick={() => setPaymentMethod('crypto')}
                    > <i className="bi bi-currency-bitcoin"></i> CRYPTO </button>
                  </div>
                </div>
              </div>
            </>}
            {paymentMethod === 'card' && <>
              <br />
              <a href='#'
                onClick={
                  (e) => {
                    e.preventDefault();
                    setPaymentMethod('none');
                  }
                }
              ><i className="bi bi-caret-left-fill"></i>{t('back')}</a>
              {userLocation && <div className='card card-body my-3 col-12 col-md-6 fade-in' style={{ minWidth: '320px' }} >
                <b>{t('cust-info')}</b>
                IP: {user.userIP}<br />
                {userLocation}
              </div>}
              {isCN && <>
              <div className='card card-body my-3 col-12 col-md-6 fade-in text-center bg-warning' style={{ minWidth: '320px' }} >
                 <i className="bi bi-exclamation-triangle" style={{ fontSize: '5em' }}></i>
                <h3>{t('area-not-supported')}</h3>
              </div>
              </>}
              {!isCN && userLocation && <>
                <Form className='card card-body my-3 col-12 col-md-6 fade-in' style={{ minWidth: '320px' }}>
                  <div className='row'>
                    <div className='col-3 col-md-2 position-relative'>
                      <img src="https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f676174657761792e697279732e78797a2f5f49554d753043386e6c5048675156534375316f674d3474656a786b44366c3970707a70414d4f63476445"
                        className='rounded position-absolute top-50 start-50 translate-middle' />
                    </div>
                    <div className='col'>
                      <span>{t('tp-token-price')}</span><br />
                      <span style={{ fontSize: '24px' }} >{unitPrice} HKD</span>
                    </div>
                    <Form.Group as={Col} controlId="inputQuantity">
                      <FormLabel>{t('tp-quantity')}</FormLabel>
                      <FormControl type="number"
                        isInvalid={errors.quantity}
                        placeholder="Quantity"
                        defaultValue={vaildationRule.minQty}
                        {
                        ...register('quantity', {
                          required: true,
                          min: vaildationRule.minQty,
                        })
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.quantity?.type === 'required' && t('tp-err-02')}
                        {errors.quantity?.type === 'min' && `${t('tp-err-03')} ${vaildationRule.minQty}`}
                      </Form.Control.Feedback>
                    </Form.Group>

                  </div>
                  <div className='row'>

                    <Form.Group as={Col} controlId="inputWallet" className='offset-3 offset-md-2'>
                      <FormLabel>{t('tp-wallet-address')}</FormLabel>
                      <InputGroup>
                        <FormControl type="text"
                          isInvalid={errors.wallet}
                          defaultValue={user.metadata?.solana_wallet_address ?? ''}
                          placeholder="Wallet Address"
                          readOnly={true}
                          {
                          ...register('wallet', {
                            required: true,
                            minLength: vaildationRule.walletAddressLen,
                          })
                          }
                        />
                        <Button className='btn btn-secondary d-none' id="button-addon2"
                          onClick={() => {
                            setValue('wallet', '');
                          }}
                        >
                          <i className="bi bi-x-circle-fill"></i>
                        </Button>
                      </InputGroup>
                      <Form.Control.Feedback type="invalid">
                        {errors.wallet?.type === 'required' && t('tp-err-04')}
                        {errors.wallet?.type === 'minLength' && t('tp-err-05')}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  {isHK && <div className='row pt-2'>
                    <FormControl type="text"
                      defaultValue={true}
                      {
                      ...register('isHK', {
                      })
                      }
                      hidden={true}
                    />
                    <Form.Group as={Col} controlId="refCode" className='offset-3 offset-md-2'>
                      <FormLabel>{t('refCode')}</FormLabel>
                      <InputGroup>
                        <FormControl type="text"
                          isInvalid={errors.refCode}
                          defaultValue={''}
                          placeholder=""
                          {
                          ...register('refCode', {
                            required: true,
                          })
                          }
                        />
                        <Button className='btn btn-secondary' id="button-addon2"
                          onClick={() => {
                            setValue('refCode', '');
                          }}
                        >
                          <i className="bi bi-x-circle-fill"></i>
                        </Button>
                      </InputGroup>
                      <Form.Control.Feedback type="invalid">
                        {errors.wallet?.type === 'required' && t('tp-err-04')}
                        {errors.wallet?.type === 'minLength' && t('tp-err-05')}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>}
                  <div className='row'>
                    <Form.Group className="mt-3">
                      <Form.Check
                        label={t('su-tnc-06')}
                        feedback={t('tp-err-01')}
                        feedbackType="invalid"
                        isInvalid={errors.agree}
                        {...register('agree', { required: true })}
                      />
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Check
                        label={<>{t('tp-tnc-01')}&nbsp;
                          <a href="/tnc/Terms-of-Use" target="_blank">{t('tp-tnc-02')}</a>,&nbsp;
                          <a href="/tnc/Disclaimer" target="_blank">{t('tp-tnc-03')}</a> {t('tp-tnc-04')}&nbsp;
                          <a href="/tnc/Privacy-Policy" target="_blank">{t('tp-tnc-05')}</a>.</>}
                        feedback={t('tp-err-01')}
                        feedbackType="invalid"
                        isInvalid={errors.agreeTnC}
                        {...register('agreeTnC', { required: true })}
                      />
                    </Form.Group>

                    <Form.Group className="mt-3">
                      <Form.Check
                        label={t('tp-tnc-06')}
                        feedback={t('tp-err-01')}
                        feedbackType="invalid"
                        isInvalid={errors.understand}
                        {...register('understand', { required: true })}
                      />
                    </Form.Group>

                    <Form.Group className="mt-3">
                      <Form.Check
                        label={t('tp-tnc-07')}
                        feedback={t('tp-err-01')}
                        feedbackType="invalid"
                        isInvalid={errors.investment}
                        {...register('investment', { required: true })}
                      />
                    </Form.Group>

                    <Form.Group className="mt-3">
                      <Form.Check
                        label={t('tp-tnc-08')}
                        feedback={t('tp-err-01')}
                        feedbackType="invalid"
                        isInvalid={errors.hereby}
                        {...register('hereby', { required: true })}
                      />
                    </Form.Group>

                  </div>
                </Form>
                <div className='card card-body bg-light col-12 col-md-6'>
                  <span className='text-nowrap'><i className="bi bi-exclamation-diamond-fill text-danger"></i>&nbsp;{t('tp-tnc-09')} </span>{i18n.language === 'en' && t('tp-tnc-10')}
                  <br />
                  {t('tp-tnc-11')}
                </div>
                {!isLoading && <button className='button-gold my-4 px-3 py-2 rounded-pill'
                  onClick={handleSubmit(handlePayment)}
                > {t('tp-buy-now')}</button>}
                {isLoading && <div class="spinner-border text-secondary my-4 mx-3" role="status"></div>}
              </>}
            </>}

            {paymentMethod === 'crypto' && <>
              <br />
              <a href='#'
                onClick={
                  (e) => {
                    e.preventDefault();
                    setPaymentMethod('none');
                  }
                }
              ><i className="bi bi-caret-left-fill"></i> back</a>
              <CryptoConverter />
            </>}
          </p>
        </div>
      </>
    );
  }

  if (paymentStatus === 'success') {
    return (<>
      <Helmet>
        <title>{title} - {t('tp-success')}</title>
      </Helmet>
      <div className="container-fluid">
        <div
          className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2">{title} - {t('tp-success')}</h1>
        </div>
        <p className='text-center' style={{ fontSize: '128px' }}>
          <i className={'bi bi-check-circle text-success'} />
        </p>
        <p>
          <div className='card card-body bg-light border-0'>
            {t('tp-success-text')}
          </div>
          <button className='button-gold my-4 px-3 py-2 rounded-pill'
            onClick={() => navigate('/console/buy')}
          > {t('tp-buy-again')} </button>
        </p>
      </div>
      <div className="w-100" style={{ height: '498px' }}>&nbsp;</div>
    </>)
  }

  if (paymentStatus === 'incomplete') {
    return (<>
      <Helmet>
        <title>{title} - {t('tp-incomplete')}</title>
      </Helmet>
      <div className="container-fluid">
        <div
          className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2">{title} - {t('tp-incomplete')}</h1>
        </div>
        <p className='text-center' style={{ fontSize: '128px' }}>
          <i className="bi bi-exclamation-diamond-fill text-danger"></i>
        </p>
        <p>
          <div className='card card-body bg-light border-0'>
            {t('tp-incomplete-text')}
          </div>

          <button className='button-gold my-4 px-3 py-2 rounded-pill'
            onClick={() => navigate('/console/buy')}
          > {t('tp-try-again')} </button>
        </p>
      </div>
      <div className="w-100" style={{ height: '498px' }}>&nbsp;</div>
    </>)
  }
}

export default Buy;
